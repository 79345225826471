import React from 'react';
import { useState } from 'react';
import { FiUnlock, FiVideo,FiServer, FiMessageSquare,FiMonitor,FiSend, FiHardDrive   ,FiAlertCircle} from 'react-icons/fi';
import { NavLink } from 'react-router-dom';



const Networkcameras= () => {
  // State for details
  const [details] = useState({
    title: 'Network Cameras',
    description:
      'Network cameras, also known as IP cameras, revolutionize surveillance by seamlessly integrating with digital networks. These sophisticated devices capture high-resolution video and transmit it over the internet, enabling remote monitoring and real-time access. With built-in Wi-Fi capabilities, they offer flexibility in placement and ease of installation. Advanced features include motion detection, night vision, and two-way audio communication, enhancing security measures. Network cameras play a pivotal role in both residential and commercial settings, providing a scalable and cost-effective solution for comprehensive surveillance systems. Their compatibility with cloud storage ensures seamless data management and accessibility. Embracing cutting-edge technology, network cameras redefine modern security infrastructure, offering users peace of mind through efficient and intelligent monitoring.',
  });

  return (
    <div className="two-section-container">
      {/* Left Section - Image and Details */}
      <div className="left-section">
        <div className="image-container">
          <img src="/images/products/NetworkCamera.png" alt="Sample" />
        </div>
        <div className="details-container">
          <div className="title-container">
            <h2>{details.title}</h2>
          </div>
          <div className="description-container">
            <p>{details.description}</p>
          </div>
        </div>
      </div>

      {/* Right Section - Links */}
      <div className="right-section">
        <div className="solink">
      

               <NavLink className="link" to="/Products/PTZcameras"  >
              <div className="icon-text-container">
              <FiVideo size={40} color="#FF5733" />
              <h6>PTZ Cameras</h6>
            </div>
              </NavLink>

              <NavLink className="link" to="/Products/Networkrecords"  >
              <div className="icon-text-container">
              <FiServer size={40} color="#0000CC" />
              <h6>Network Records</h6>
            </div>
              </NavLink>

           <NavLink className="link" to="/Products/Videointercoms"  >
              <div className="icon-text-container">
              <FiMessageSquare size={40} color="#FFD700" />
              <h6>Video Intercoms</h6>
            </div>
              </NavLink>

              <NavLink className="link" to="/Products/Videocon"  >
              <div className="icon-text-container">
              <FiMonitor size={40} color="#8E44AD" />
              <h6>Video Conferencing</h6>
            </div>
              </NavLink>
            


              <NavLink className="link" to="/Products/Transmission"  >
              <div className="icon-text-container">
              <FiSend size={40} color="green" />
              <h6>Transmission</h6>
            </div>
              </NavLink>

              <NavLink className="link" to="/Products/Storage"  >
              <div className="icon-text-container">
              <FiHardDrive    size={40} color="#424242" />
              <h6>Storage</h6>
            </div>
              </NavLink>

              <NavLink className="link" to="/Products/Firealarm"  >
              <div className="icon-text-container">
              <FiAlertCircle size={40} color="#CC0000" />
              <h6>Fire Alarm</h6>
            </div>
              </NavLink>

              <NavLink className="link" to="/Products/Accesscontrol"  >
              <div className="icon-text-container">
              <FiUnlock size={45} color="#641E16" />
              <h6>Access Control &<br/> Time Attendance</h6>
            </div>
              </NavLink>
        </div>
      </div>
    </div>
  );
};

export default Networkcameras;
