import React, { useState } from 'react';
import { FaEnvelope, FaMapMarkerAlt, FaPhone } from 'react-icons/fa';

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = () => {
    const { name, email, message } = formData;
    const mailtoLink = `mailto:eskidigitaltime@gmail.com?subject=Message from ${name}&body=${message}%0D%0A%0D%0AReply to: ${email}`;
    window.location.href = mailtoLink;
  };

  return (
    <div className='contain'>
      <div className='Contactt' style={{ display: 'flex' }}>
        <div style={{ flex: 1, padding: '10px' }}>
          <h1>Contact Us</h1>
          <form >
            <div>
              <label>Name:</label>
              <input type="text" name="name" value={formData.name} onChange={handleInputChange} />
            </div>
            <div>
              <label>Email:</label>
              <input type="email" name="email" value={formData.email} onChange={handleInputChange} />
            </div>
            <div>
              <label>Message:</label>
              <textarea name="message" value={formData.message} onChange={handleInputChange} />
            </div>
            <button style={{marginLeft:'165px', borderRadius:'30px'}} type="button" onClick={handleSubmit}>Submit</button>
          </form>
        </div>
        <div  style={{ padding: '10px', marginTop: '20px', maxWidth: '600px'}}>
        <div className="contact-info" >
          <p><FaMapMarkerAlt color='whitesmoke'/> Bole Medaniyalm Morning Star Mall, 3rd floor Office number 316</p>
          <p><FaPhone color='whitesmoke' /> Mobile:+251-911-71-0194 or +251-116-72-4546</p>
          <p><FaEnvelope color='whitesmoke'/> Email: info@digitaltimeplc.com</p>
          <p><FaEnvelope color='whitesmoke' /> P.O.BOX: 101010 Addis Ababa, Ethiopia</p>
        </div>
      </div>  
      </div>
      <div className="map-container">
        <iframe
          title="Digital Time Location"
          width="100%"
          height="450"
          frameBorder="0"
          style={{ border: 0 }}
          allowFullScreen
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3940.731982552276!2d38.783854674101235!3d8.996791389494195!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x164b850257a12975%3A0xe2468e96cfb05b5b!2sMorning%20Star%20Mall!5e0!3m2!1sen!2set!4v1704190936886!5m2!1sen!2set"
        ></iframe>
      </div>
    </div>
  );
};

export default ContactUs;
