import {
  createBrowserRouter,
  RouterProvider,
 // Route,
  Outlet,
} from "react-router-dom";

import Home from "./pages/Home";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import "./style.scss"
import NotFound from "./pages/Coming soon/Page404";
import About from "./pages/About";
import NetworkCameras from "./pages/Product/Networkcameras";
import PTZcameras from "./pages/Product/PTZcameras";

import Networkrecords from "./pages/Product/NetworkRecords";
import Videointercoms from "./pages/Product/Videointercoms";
import Accesscontrol from "./pages/Product/Accesscontrol";
import Transmission from "./pages/Product/Transmission";
import Videocon from "./pages/Product/Videocon";
import Storage from "./pages/Product/Storage";
import Firealarm from "./pages/Product/Firealarm";

import Maintainance from "./pages/Solution/Maintainance";
import Management from "./pages/Solution/Management"
import Monitoring from "./pages/Solution/Monitoring"
import ContactUs from "./pages/ContactUs";
import Privacy from './Privacy';
import Terms from "./Terms";




const Layout = () => {
  return (
    <>
      <Navbar />
      <Outlet />
      <Footer />
    </>
  );
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        path: "/",
        element: <Home />,
      },

  {
    path: "/About",
    element: <About />,
  },
  {
    path: "/Products/Networkcameras",
    element: <NetworkCameras />,
  },
  {
    path: "/products/PTZcameras", 
    element: <PTZcameras />,
  },
  {
    path: "/Products/Networkrecords",
    element: <Networkrecords />,
  },
  {
    path: "/Products/Videointercoms",
    element: <Videointercoms />,
  },
  {
    path: "/Products/Accesscontrol",
    element: <Accesscontrol />,
  },
  {
    path: "/Products/Transmission",
    element: <Transmission />,
  },
  {
    path: "/Products/Videocon",
    element: <Videocon />,
  },
  {
    path: "/Products/Storage",
    element: <Storage />,
  },
  {
    path: "/Products/Firealarm",
    element: <Firealarm />,
  },
  {
    path: "/Solutions/Maintainance",
    element: <Maintainance/>,
  },

  {
    path: "/Solutions/Management",
    element: <Management/>,
  },

  
  {
    path: "/Solutions/Monitoring",
    element: <Monitoring/>,
  },
 
  {
    path: "/ContactUs",
    element: <ContactUs />,
  },
  {
    path: "/foods",
    element: <NotFound />,
  },    
  {
    path: "/Privacy",
    element: <Privacy/>,
  }, 
  {
    path: "/Terms",
    element: <Terms/>,
  },   
],},
]);

function App() {
  return (
    <div className="app">
      <div className="container">
        <RouterProvider router={router} />
      </div>
    </div>
  );
}

export default App;
