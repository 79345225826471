import React from 'react';
import { useState } from 'react';
import { FiShield, FiMonitor } from 'react-icons/fi';
import { NavLink } from 'react-router-dom';


const Maintainance = () => {
  // State for details
  const [details] = useState({
    title: 'Maintenance and Upgrading',
    description:
      'After any design and installation of a security system, there will be the issue of maintaining the system on hand and additional installation. Our company, Digital Time Security Technology Service, will undertake the maintenance and additional installation of your company in a determined, skilled, and experienced way. From our experience and expertise in the field of security management, we took every detailed step of maintaining the security system, and all of the time, we perform the task efficiently.',
  });

  return (
    <div className="two-section-container">
      {/* Left Section - Image and Details */}
      <div className="left-section">
        <div className="image-container">
          <img src="/images/ma.jpg" alt="Sample" />
        </div>
        <div className="details-container">
          <div className="title-container">
            <h2>{details.title}</h2>
          </div>
          <div className="description-container">
            <p>{details.description}</p>
          </div>
        </div>
      </div>

      {/* Right Section - Links */}
      <div className="right-section">
        <div className="solink">
          <NavLink className="link" to="/Solutions/Management">
            <div className="icon-text-container">
              <FiShield size={55} color="#000066" />
              <h6>Management of security system</h6>
            </div>
          </NavLink>

          <NavLink className="link" to="/Solutions/Monitoring">
            <div className="icon-text-container">
              <FiMonitor size={55} color="#FFCC00" />
              <h6>Monitoring the system</h6>
            </div>
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default Maintainance;
