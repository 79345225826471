// NotFound.js
import React from 'react';

import './NotFound.css'; // Import the CSS file for styling

const NotFound = () => {
  return (
    <div className="not-found-container">
      <div className="emoji">😕</div>
      <h1>404 - COMING SOON</h1>
    </div>
  );
};

export default NotFound;
