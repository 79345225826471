import React from 'react';
import { useState } from 'react';
import { FiCamera, FiVideo,FiServer, FiMessageSquare,FiMonitor,FiSend, FiHardDrive   ,FiAlertCircle} from 'react-icons/fi';
import { NavLink } from 'react-router-dom';


const Accesscontrol = () => {
  // State for details
  const [details] = useState({
    title: 'Access Control and Time Attendance',
    description:
      'Access control and time attendance systems are integral components of modern security and workforce management. Access control restricts entry to authorized personnel, safeguarding sensitive areas and data. Utilizing biometric authentication, key cards, or PINs, it ensures only designated individuals gain entry, enhancing overall security. Simultaneously, time attendance systems track employees work hours, promoting efficiency and transparency. By recording clock-ins and outs these systems streamline payroll processes and provide valuable insights into workforce attendance patterns. The integration of access control and time attendance not only fortifies organizational security but also optimizes operational efficiency contributing to a seamless and well-managed work environment.',
  });

  return (
    <div className="two-section-container">
      {/* Left Section - Image and Details */}
      <div className="left-section">
        <div className="image-container">
          <img src="/images/products/ACTA.png" alt="Sample" />
        </div>
        <div className="details-container">
          <div className="title-container">
            <h2>{details.title}</h2>
          </div>
          <div className="description-container">
            <p>{details.description}</p>
          </div>
        </div>
      </div>

      {/* Right Section - Links */}
      <div className="right-section">
        <div className="solink">
        <NavLink className="link" to="/Products/Networkcameras"  >
              <div className="icon-text-container">
              <FiCamera size={40} color="#1A237E" />
              <h6>Network Cameras</h6>
            </div>
              </NavLink>

               <NavLink className="link" to="/Products/PTZcameras"  >
              <div className="icon-text-container">
              <FiVideo size={40} color="#FF5733" />
              <h6>PTZ Cameras</h6>
            </div>
              </NavLink>

              <NavLink className="link" to="/Products/Networkrecords"  >
              <div className="icon-text-container">
              <FiServer size={40} color="#0000CC" />
              <h6>Network Records</h6>
            </div>
              </NavLink>

           <NavLink className="link" to="/Products/Videointercoms"  >
              <div className="icon-text-container">
              <FiMessageSquare size={40} color="#FFD700" />
              <h6>Video Intercoms</h6>
            </div>
              </NavLink>

              <NavLink className="link" to="/Products/Videocon"  >
              <div className="icon-text-container">
              <FiMonitor size={40} color="#8E44AD" />
              <h6>Video Conferencing</h6>
            </div>
              </NavLink>
            


              <NavLink className="link" to="/Products/Transmission"  >
              <div className="icon-text-container">
              <FiSend size={40} color="green" />
              <h6>Transmission</h6>
            </div>
              </NavLink>

              <NavLink className="link" to="/Products/Storage"  >
              <div className="icon-text-container">
              <FiHardDrive    size={40} color="#424242" />
              <h6>Storage</h6>
            </div>
              </NavLink>

              <NavLink className="link" to="/Products/Firealarm"  >
              <div className="icon-text-container">
              <FiAlertCircle size={40} color="#CC0000" />
              <h6>Fire Alarm</h6>
            </div>
              </NavLink>
        </div>
      </div>
    </div>
  );
};

export default Accesscontrol;
