import React from "react";
import Logo from "../img/logo.png";
import { FaFacebook, FaTwitter, FaInstagram, FaEnvelope, FaPhone, FaMapMarkerAlt } from "react-icons/fa";
import { Link } from "react-router-dom";


const Footer = () => {
  return (
    <div className="footer">
      <footer>
        <div className="footer-container">
        <div className="logo-section">
            <img src={Logo} alt="Digital Logo" />
            <p>
       Digital Time corporate camera installation and development services have been 
       delivering internet communication solutions.
            </p>
        
          </div>
          
          <div className="links-section">
            <div className="links-columnn">
              <h3>Company</h3>
              <Link to="/Privacy">Privacy Policy</Link>
      <Link to="/Terms">Terms of Use</Link>
            </div>
          </div>

          <div className="contact-section">
            <h3>Contact Us</h3>
            <p><FaMapMarkerAlt color="rgb(111, 180, 207)"/> : Bole Medaniyalm Morning Star Mall,<br/>3rd floor Office number 316 </p>
            <p><FaEnvelope color="rgb(111, 180, 207)"/> :  info@digitaltimeplc.com</p>   
            <p><FaPhone color="rgb(111, 180, 207)"/> : +251-911-71-0194 or +251-116-72-4546</p>
          </div>

           <div className="links-column">
              <h3>Social Media</h3>
              <a href="https://www.facebook.com/yourpage">
                <FaFacebook /> 
              </a>
              <a href="https://www.instagram.com/yourpage">
                <FaInstagram />
              </a>
              <a href="https://www.twitter.com/yourpage">
                <FaTwitter /> 
              </a>
            </div>

        </div>
        <div className="powered-by">
        <p style={{paddingBottom:'10px'}}>&copy; {new Date().getFullYear()} Digital Time. All rights reserved.</p>
          <p><strong>Powered by : Softnet Solutions™</strong></p>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
