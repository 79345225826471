import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

const link = document.querySelector("link[rel*='icon']") || document.createElement('link');
link.type = 'image/x-icon';
link.rel = 'shortcut icon';
link.href = '/images/favicon.ico'; // Updated href to point to the images folder
document.getElementsByTagName('head')[0].appendChild(link);

document.title = "Digital Time"; // Add this line to change the document title
