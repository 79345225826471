import React from 'react';

const Terms= () => {
  return (
    <div className='terms'>
      <h1>Terms of Use</h1>
      <p>
        By using our website and services, you agree to comply with and be bound by these
        Terms of Use.
      </p>

      <h2 >Use of Content:</h2>
      <p>
        The content on this website is for informational purposes only. Reproduction or
        distribution without our permission is prohibited.
      </p>

      {/* Add more sections as needed */}

      <h2>Governing Law:</h2>
      <p>
        These Terms of Use are governed by the laws of the applicable Jurisdiction.
      </p>

      {/* Add more sections as needed */}
    </div>
  );
};

export default Terms;
