// Card.js
import React from "react";
import { Link } from "react-router-dom";

const Card = ({ imageSrc, title, description, link, price }) => {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <Link to={link} className="card" onClick={scrollToTop}>
      <img src={imageSrc} alt={title} />
      <div className="card-content">
        <h3>{title}</h3>
        <p>{description}</p>
        {/* <span className="price">${price}</span> */}
        <button>Read More</button>
      </div>
    </Link>
  );
};

export default Card;

