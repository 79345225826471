import React from 'react';
import Experience from './Experience'; // Import the Experience component

const NetworkCameras = () => {
  const posts = [
    {
      id: 1,
      title: "",
      desc: (
        
        <div>
          <h1>About Us</h1>
         <p><br/> <strong>Digital Time</strong>  is a VAT Registered company, and a brand name Registered in Ethiopia Ministry of Trade (MOT). Digital Time has the experience and a proven track record for creating effective, profitable corporate design, project development that will maximize your return on investment.</p><br/><br/>
          <p>Our corporate solutions will provide tangible results and a realistic budget that fits your business model for a successful security technology. As a comprehensive security company, our focus is to specialize in the combination of high-impact design with user friendly interface and functionality that insure your security.</p><br/>
          <p>Digital Time corporate camera installation and development services have been delivering internet communication solutions that employ a wide range of cutting-edge technologies with proven results.</p><br/><br/><br/>
          <h2><strong>What can we bring to the table?</strong></h2><br/>
          <p><strong>Digital Time Security Technologies </strong>brings experience, and digital 
              intelligence with skilled development of advanced programs. <br/><br/>
              Unlike many of the younger firms in security 
              camera installation and software configuration
              we’ve spent a great deal of time working 
              with private corporate, Public NGO and 
              Government entities addressing real and 
              virtual issues for providing solutions that will 
              maximize a return on investment and with 
              cutting age designs and programming to surpass 
              online presence limitations</p>
        </div>
      ),
      img: "./images/About.jpg"
    }
  ];
  
  return (
    <div className="about">
      <div className="posts">
        {posts.map((post) => (
          <div className="post" key={post.id}>
            <div className="img">
              <img src={post.img} alt="" />
            </div>
            <div className="content">
              <h1>{post.title}</h1>
              <p>{post.desc}</p>
              <Experience /> {/* Integrate the Experience component here */}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default NetworkCameras;
