import React from 'react';
import { useState } from 'react';
import { FiCamera, FiVideo,FiServer, FiMessageSquare,FiMonitor,FiSend, FiHardDrive   ,FiUnlock} from 'react-icons/fi';
import { NavLink } from 'react-router-dom';


const Accesscontrol = () => {
  // State for details
  const [details] = useState({
    title: 'Fire Alarm System',
    description:
      'A fire alarm is a critical safety device designed to detect the presence of fire and alert occupants to potential danger. Typically installed in residential, commercial, and industrial settings, these systems employ various sensors like smoke detectors or heat sensors to identify early signs of a fire outbreak. When triggered, the alarm emits a loud, distinctive sound, serving as a warning signal to evacuate the premises swiftly. Modern fire alarms may also integrate with building management systems, enabling swift response from emergency services. With their crucial role in early detection, fire alarms play a pivotal role in minimizing property damage and, most importantly, safeguarding lives during emergencies. Regular maintenance and testing ensure the reliability of these essential safety components.',
  });

  return (
    <div className="two-section-container">
      {/* Left Section - Image and Details */}
      <div className="left-section">
        <div className="image-container">
          <img src="/images/products/FireAlarm.png" alt="Sample" />
        </div>
        <div className="details-container">
          <div className="title-container">
            <h2>{details.title}</h2>
          </div>
          <div className="description-container">
            <p>{details.description}</p>
          </div>
        </div>
      </div>

      {/* Right Section - Links */}
      <div className="right-section">
        <div className="solink">
        <NavLink className="link" to="/Products/Networkcameras"  >
              <div className="icon-text-container">
              <FiCamera size={40} color="#1A237E" />
              <h6>Network Cameras</h6>
            </div>
              </NavLink>

               <NavLink className="link" to="/Products/PTZcameras"  >
              <div className="icon-text-container">
              <FiVideo size={40} color="#FF5733" />
              <h6>PTZ Cameras</h6>
            </div>
              </NavLink>

              <NavLink className="link" to="/Products/Networkrecords"  >
              <div className="icon-text-container">
              <FiServer size={40} color="#0000CC" />
              <h6>Network Records</h6>
            </div>
              </NavLink>

           <NavLink className="link" to="/Products/Videointercoms"  >
              <div className="icon-text-container">
              <FiMessageSquare size={40} color="#FFD700" />
              <h6>Video Intercoms</h6>
            </div>
              </NavLink>

              <NavLink className="link" to="/Products/Videocon"  >
              <div className="icon-text-container">
              <FiMonitor size={40} color="#8E44AD" />
              <h6>Video Conferencing</h6>
            </div>
              </NavLink>
            


              <NavLink className="link" to="/Products/Transmission"  >
              <div className="icon-text-container">
              <FiSend size={40} color="green" />
              <h6>Transmission</h6>
            </div>
              </NavLink>

              <NavLink className="link" to="/Products/Storage"  >
              <div className="icon-text-container">
              <FiHardDrive    size={40} color="#424242" />
              <h6>Storage</h6>
            </div>
              </NavLink>

              <NavLink className="link" to="/Products/Accesscontrol"  >
              <div className="icon-text-container">
              <FiUnlock size={45} color="#641E16" />
              <h6>Access Control &<br/> Time Attendance</h6>
            </div>
              </NavLink>
        </div>
      </div>
    </div>
  );
};

export default Accesscontrol;
