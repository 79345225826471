import React from 'react';
import { useState } from 'react';
import { FiUnlock, FiVideo,FiCamera, FiMessageSquare,FiMonitor,FiSend, FiHardDrive   ,FiAlertCircle} from 'react-icons/fi';
import { NavLink } from 'react-router-dom';


const NetworkRecords = () => {
  // State for details
  const [details] = useState({
    title: 'Network Records',
    description:
      'Network Records serve as the vital backbone of digital communication, enabling seamless connectivity and data exchange across devices and systems. These records encapsulate the intricate web of relationships, configurations, and interactions within a network, facilitating efficient information flow. From IP addresses to routing protocols, each element contributes to the intricate tapestry of network records. They not only define the structural architecture but also capture the dynamic nature of connections in real-time. Security protocols and monitoring mechanisms rely on these records to detect anomalies and safeguard against potential threats. As the digital landscape evolves, the meticulous management and analysis of network records become paramount, ensuring the reliability, security, and optimal performance of interconnected systems in our increasingly interconnected world.',
  });

  return (
    <div className="two-section-container">
      {/* Left Section - Image and Details */}
      <div className="left-section">
        <div className="image-container">
          <img src="/images/products/NetworkRecord.png" alt="Sample" />
        </div>
        <div className="details-container">
          <div className="title-container">
            <h2>{details.title}</h2>
          </div>
          <div className="description-container">
            <p>{details.description}</p>
          </div>
        </div>
      </div>

      {/* Right Section - Links */}
      <div className="right-section">
        <div className="solink">
      
        <NavLink className="link" to="/Products/Networkcameras"  >
              <div className="icon-text-container">
              <FiCamera size={40} color="#1A237E" />
              <h6>Network Cameras</h6>
            </div>
              </NavLink>
               <NavLink className="link" to="/Products/PTZcameras"  >
              <div className="icon-text-container">
              <FiVideo size={40} color="#FF5733" />
              <h6>PTZ Cameras</h6>
            </div>
              </NavLink>


           <NavLink className="link" to="/Products/Videointercoms"  >
              <div className="icon-text-container">
              <FiMessageSquare size={40} color="#FFD700" />
              <h6>Video Intercoms</h6>
            </div>
              </NavLink>

              <NavLink className="link" to="/Products/Videocon"  >
              <div className="icon-text-container">
              <FiMonitor size={40} color="#8E44AD" />
              <h6>Video Conferencing</h6>
            </div>
              </NavLink>
            


              <NavLink className="link" to="/Products/Transmission"  >
              <div className="icon-text-container">
              <FiSend size={40} color="green" />
              <h6>Transmission</h6>
            </div>
              </NavLink>

              <NavLink className="link" to="/Products/Storage"  >
              <div className="icon-text-container">
              <FiHardDrive    size={40} color="#424242" />
              <h6>Storage</h6>
            </div>
              </NavLink>

              <NavLink className="link" to="/Products/Firealarm"  >
              <div className="icon-text-container">
              <FiAlertCircle size={40} color="#CC0000" />
              <h6>Fire Alarm</h6>
            </div>
              </NavLink>

              <NavLink className="link" to="/Products/Accesscontrol"  >
              <div className="icon-text-container">
              <FiUnlock size={45} color="#641E16" />
              <h6>Access Control &<br/> Time Attendance</h6>
            </div>
              </NavLink>

              
        </div>
      </div>
    </div>
  );
};

export default NetworkRecords;
