import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import Logo from "../img/digita.png";
import { FiChevronDown } from 'react-icons/fi';
import {  FiCamera, FiVideo, FiMessageSquare, FiUnlock, FiServer, FiSend, FiMonitor, FiSettings, 
  FiAlertCircle, FiShield, FiTool,} from 'react-icons/fi';
import Contac from "./Contac";
 



const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  

  return (
    
  <div className={`navbar ${isOpen ? 'mobile-open' : ''}`}>
     
    <Contac/>

      <div className="container">
        <div className="logo">
          <NavLink to="/" onClick={closeMenu}>
            <img src={Logo} alt="" />
          </NavLink>
        </div>
        <button className={`toggle-button ${isOpen ? 'open' : ''}`} onClick={handleToggle}>
       {isOpen ? '' : ''}
        </button>
        <div className={`links ${isOpen ? 'open' : ''}`}>
        <NavLink className="link" to="/" onClick={closeMenu} >
            <h6>Home</h6>
          </NavLink>

          <NavLink className="link" to="/about" onClick={closeMenu} >
            <h6>About</h6>
          </NavLink>

          <div className="dropdown">
            <button className="dropbtn" >Products <FiChevronDown color="#007BFF" />
            </button>
            <div className="dropdown-content">
              <NavLink className="link" to="/Products/Networkcameras" onClick={closeMenu} >
              <div className="icon-text-container">
              <FiCamera size={25} color="#1A237E" />
              <h6>Network Cameras</h6>
            </div>
              </NavLink>

               <NavLink className="link" to="/Products/PTZcameras" onClick={closeMenu} >
              <div className="icon-text-container">
              <FiVideo size={25} color="#FF5733" />
              <h6>PTZ Cameras</h6>
            </div>
              </NavLink>

              <NavLink className="link" to="/Products/Networkrecords" onClick={closeMenu} >
              <div className="icon-text-container">
              <FiServer size={25} color="#0000CC" />
              <h6>Network Records</h6>
            </div>
              </NavLink>

           <NavLink className="link" to="/Products/Videointercoms" onClick={closeMenu} >
              <div className="icon-text-container">
              <FiMessageSquare size={25} color="#FFD700" />
              <h6>Video Intercoms</h6>
            </div>
              </NavLink>

              <NavLink className="link" to="/Products/Videocon" onClick={closeMenu} >
              <div className="icon-text-container">
              <FiMonitor size={25} color="#8E44AD" />
              <h6>Video Conferencing</h6>
            </div>
              </NavLink>
            

              <NavLink className="link" to="/Products/Accesscontrol" onClick={closeMenu} >
              <div className="icon-text-container">
              <FiUnlock size={25} color="#641E16" />
              <h6>Access Control & Time Attendance</h6>
            </div>
              </NavLink>

              <NavLink className="link" to="/Products/Transmission" onClick={closeMenu} >
              <div className="icon-text-container">
              <FiSend size={25} color="green" />
              <h6>Transmission</h6>
            </div>
              </NavLink>

              <NavLink className="link" to="/Products/Storage" onClick={closeMenu} >
              <div className="icon-text-container">
              <FiSettings size={25} color="#424242" />
              <h6>Storage</h6>
            </div>
              </NavLink>

              <NavLink className="link" to="/Products/Firealarm" onClick={closeMenu} >
              <div className="icon-text-container">
              <FiAlertCircle size={25} color="#CC0000" />
              <h6>Fire Alarm</h6>
            </div>
              </NavLink>
              {/* Add other dropdown items */}
            </div>
          </div>

          <div className="dropdown">
            <button className="dropbtn" >Solutions <FiChevronDown color="#007BFF"/>    
            </button>
            <div className="dropdown-content">
             <NavLink className="link" to="/Solutions/Management" onClick={closeMenu} >
              <div className="icon-text-container">
              <FiShield size={25} color="#000066" />
              <h6>Management of security system</h6>
            </div>
              </NavLink>

              <NavLink className="link" to="/Solutions/Maintainance" onClick={closeMenu} >
              <div className="icon-text-container">
              <FiTool size={25} color="#990000" />
              <h6>Maintainance & Upgrading</h6>
            </div>
              </NavLink>

              <NavLink className="link" to="/Solutions/Monitoring" onClick={closeMenu} >
              <div className="icon-text-container">
              <FiMonitor size={25} color="#FFCC00" />
              <h6>Monitoring the system</h6>
            </div>
              </NavLink>
        
          
              {/* Add other dropdown items */}
            </div>
          </div>
          
          <NavLink className="link" to="/ContactUs" onClick={closeMenu} >
            <h6>Contact Us</h6>
          </NavLink>  
          {/* Add authentication and other menu items */}
        </div>
      </div>
    </div>
  );
};

export default Navbar;
