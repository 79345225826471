import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div>
      <h1>Privacy Policy</h1>
      <p>
        Welcome to Digital-Time, a trusted provider of security systems.
        
      </p>

      

      {/* Add more sections as needed */}

      

      {/* Add more sections as needed */}
    </div>
  );
};

export default PrivacyPolicy;
