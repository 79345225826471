import React from 'react';
import { useState } from 'react';
import { FiCamera, FiUnlock,FiServer, FiMessageSquare,FiMonitor,FiSend, FiHardDrive   ,FiAlertCircle} from 'react-icons/fi';
import { NavLink } from 'react-router-dom';


const PTZcameras = () => {
  // State for details
  const [details] = useState({
    title: 'PTZ Cameras',
    description:
      'PTZ (Pan-Tilt-Zoom) cameras are versatile surveillance devices renowned for their dynamic functionality. These cameras enable remote control over pan, tilt, and zoom features, providing a comprehensive field of view for monitoring expansive areas. With pan and tilt capabilities, PTZ cameras can rotate horizontally and vertically, ensuring optimal coverage. The zoom function allows users to magnify distant details, enhancing surveillance precision. Equipped with advanced technology, PTZ cameras often feature automatic tracking, motion detection, and preset positioning for efficient monitoring. Ideal for various applications, including security, traffic management, and live event coverage, PTZ cameras deliver heightened situational awareness and can be seamlessly integrated into existing surveillance systems for robust, responsive monitoring solutions. ',
  });

  return (
    <div className="two-section-container">
      {/* Left Section - Image and Details */}
      <div className="left-section">
        <div className="image-container">
          <img src="/images/products/PTZcamera.png" alt="Sample" />
        </div>
        <div className="details-container">
          <div className="title-container">
            <h2>{details.title}</h2>
          </div>
          <div className="description-container">
            <p>{details.description}</p>
          </div>
        </div>
      </div>

      {/* Right Section - Links */}
      <div className="right-section">
        <div className="solink">
        <NavLink className="link" to="/Products/Networkcameras"  >
              <div className="icon-text-container">
              <FiCamera size={40} color="#1A237E" />
              <h6>Network Cameras</h6>
            </div>
              </NavLink>

         

              <NavLink className="link" to="/Products/Networkrecords"  >
              <div className="icon-text-container">
              <FiServer size={40} color="#0000CC" />
              <h6>Network Records</h6>
            </div>
              </NavLink>

           <NavLink className="link" to="/Products/Videointercoms"  >
              <div className="icon-text-container">
              <FiMessageSquare size={40} color="#FFD700" />
              <h6>Video Intercoms</h6>
            </div>
              </NavLink>

              <NavLink className="link" to="/Products/Videocon"  >
              <div className="icon-text-container">
              <FiMonitor size={40} color="#8E44AD" />
              <h6>Video Conferencing</h6>
            </div>
              </NavLink>
            


              <NavLink className="link" to="/Products/Transmission"  >
              <div className="icon-text-container">
              <FiSend size={40} color="green" />
              <h6>Transmission</h6>
            </div>
              </NavLink>

              <NavLink className="link" to="/Products/Storage"  >
              <div className="icon-text-container">
              <FiHardDrive    size={40} color="#424242" />
              <h6>Storage</h6>
            </div>
              </NavLink>

              <NavLink className="link" to="/Products/Firealarm"  >
              <div className="icon-text-container">
              <FiAlertCircle size={40} color="#CC0000" />
              <h6>Fire Alarm</h6>
            </div>
              </NavLink>

              <NavLink className="link" to="/Products/Accesscontrol"  >
              <div className="icon-text-container">
              <FiUnlock size={45} color="#641E16" />
              <h6>Access Control &<br/> Time Attendance</h6>
            </div>
              </NavLink>
        </div>
      </div>
    </div>
  );
};

export default PTZcameras;
