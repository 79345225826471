import React, { useState } from 'react';

const Experience = () => {
  const [visibleCount, setVisibleCount] = useState(5); // Number of experiences initially visible

  const loadMore = () => {
    setVisibleCount(prevCount => prevCount + 8); // Increase the visible count by 5
  };

  const experiences = [
    {
      logo: '/images/logo/EA.png',
      company: 'Ethiopian Airlines',
      responsibilities: 'Domestic flight sound and fire alarm system.'
    },
    {
      logo: '/images/logo/SPH.png',
      company: 'St.Paulos Hospital',
      responsibilities: 'Camera installation. '
    },
    
      {
        logo: '/images/logo/BE.png',
        company: 'British Embassy',
        responsibilities: 'Fire alarm.'
      },
      {
        logo: '/images/logo/MSM.png',
        company: 'Morning Star Mall',
        responsibilities: ' Security Surveillance Camera and Wi-Fi.'
      },

      {
        logo: '/images/logo/ET.png',
        company: 'Embassy of Tunisia, Residence',
        responsibilities: ' Design and installation Security Surveillance camera. '
      },
      {
        logo: '/images/logo/SSH.png',
        company: 'Shoa Super & Hypermarket',
        responsibilities: ' Over 17 branches complete IT security solutions.'
      },
     
      {
        logo: '/images/logo/MET.png',
        company: 'Metec Engineering & Industry',
        responsibilities: 'Camera installation.'
      },
      
      {
        logo: '/images/logo/HKN.png',
        company: 'Hayal Kassa Nails Fabrics',
        responsibilities: 'camera installation.'
      },
      {
        logo: '/images/logo/MSD.png',
        company: 'Moha Soft Drink Industry',
        responsibilities: 'camera installation.'
      },
      {
        logo: '/images/logo/CE.png',
        company: 'Embassy of China, Residence ',
        responsibilities: 'Intercom system.'
      },
      
      {
        logo: '/images/logo/SWS.png',
        company: 'Safeway Supermarkets',
        responsibilities: 'Camera installation.'
      },
      {
        logo: '/images/logo/wu.png',
        company: 'Wolayta University',
        responsibilities: 'Design and installation of 120 pc Security Surveillance camera.'
      },
      {
        logo: '/images/logo/LS.png',
        company: 'Lavender Supermarket',
        responsibilities: 'Camera installation.'
      },
      {
        logo: '/images/logo/UI.png',
        company: 'United Insurance',
        responsibilities: 'Camera installation.'
      },
      {
        logo: '/images/logo/VV.png',
        company: 'VFS Global Visa Center',
        responsibilities: 'Access Control & CCTV.'
      },
      {
        logo: '/images/logo/AW.png',
        company: 'Awash Wine',
        responsibilities: ' Camera installation. '
      },
      
      {
        logo: '/images/logo/GT.png',
        company: 'Guna Trading',
        responsibilities: 'Network Installation. '
      },
      {
        logo: '/images/logo/FTV.png',
        company: 'FTV Trading',
        responsibilities: ' Network Installation. '
      },
      {
        logo: '/images/logo/ADB.png',
        company: 'Amole - Dashen Bank',
        responsibilities: 'Access Control & Camera installation. '
      },
      {
        logo: '/images/logo/CSC.png',
        company: 'CEO & Surrender Clubs',
        responsibilities: 'Camera installation. '
      },
      {
        logo: '/images/logo/MH.jpeg',
        company: 'Motera Hotel',
        responsibilities: 'Camera installation. '
      },
      {
        logo: '/images/logo/KD.jpeg',
        company: ' Damot Industrial Commercial PLC',
        responsibilities: 'Camera installation. '
      },
      

   
  ];

  return (
    <div className="experience-section">
      <h2>Experience</h2>
      <h3 style={{margin:'10px 10px ', fontSize:'22px'}}>Our Clients</h3>
      <div className="experience-items">
        {experiences.slice(1, visibleCount).map((experience, index) => (
          <div className="experience-item" key={index}>
            <img src={experience.logo} alt={`Company Logo ${index}`} />
            <div className="experience-details">
              <h3>{experience.company}</h3>
              <p><strong>Duty : </strong>{experience.responsibilities}</p>
            </div>
          </div>
        ))}
      </div>
      {visibleCount < experiences.length && (
        <div className="exp">
        <button onClick={loadMore}>Load More</button>
        </div>
      )}
    </div>
  );
};

export default Experience;
