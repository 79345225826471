import React, { useEffect, useState } from 'react';
import { FaFacebook, FaTwitter, FaTelegram, FaPhone, FaMapMarkerAlt } from 'react-icons/fa';


const Contac = () => {
  const [isTop, setIsTop] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      setIsTop(scrollTop === 0);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className={`contact-infoo ${isTop ? '' : 'scrolled'}`}>
      <p><FaPhone/> : +251-116-72-4546</p>
      <p><FaMapMarkerAlt/>: Bole Medaniyalm Morning Star Mall, 3rd floor Office number 316 </p>
      <div className="social-links">
        <a href="https://www.facebook.com/example" className="social-icon"><FaFacebook /></a>
        <a href="https://www.twitter.com/example" className="social-icon"><FaTwitter /></a>
        <a href="https://www.telegram.com/example" className="social-icon"><FaTelegram /></a>
      </div>
    </div>
  );
};

export default Contac;
