import React from 'react';
import { useState } from 'react';
import { FiCamera, FiVideo,FiServer, FiMessageSquare,FiMonitor,FiSend, FiUnlock   ,FiAlertCircle} from 'react-icons/fi';
import { NavLink } from 'react-router-dom';


const Storage = () => {
  // State for details
  const [details] = useState({
    title: 'Storage',
    description:
      'Storage is a crucial component in security systems as it involves the retention and management of data collected by various sensors, cameras, and other devices within the system. Proper storage solutions are essential for archiving, reviewing, and analyzing historical data, as well as for complying with legal and regulatory requirements.',
  });

  return (
    <div className="two-section-container">
      {/* Left Section - Image and Details */}
      <div className="left-section">
        <div className="image-container">
          <img src="/images/products/storage.png" alt="Sample" />
        </div>
        <div className="details-container">
          <div className="title-container">
            <h2>{details.title}</h2>
          </div>
          <div className="description-container">
            <p>{details.description}</p>
          </div>
        </div>
      </div>

      {/* Right Section - Links */}
      <div className="right-section">
        <div className="solink">
        <NavLink className="link" to="/Products/Networkcameras"  >
              <div className="icon-text-container">
              <FiCamera size={40} color="#1A237E" />
              <h6>Network Cameras</h6>
            </div>
              </NavLink>

               <NavLink className="link" to="/Products/PTZcameras"  >
              <div className="icon-text-container">
              <FiVideo size={40} color="#FF5733" />
              <h6>PTZ Cameras</h6>
            </div>
              </NavLink>

              <NavLink className="link" to="/Products/Networkrecords"  >
              <div className="icon-text-container">
              <FiServer size={40} color="#0000CC" />
              <h6>Network Records</h6>
            </div>
              </NavLink>

           <NavLink className="link" to="/Products/Videointercoms"  >
              <div className="icon-text-container">
              <FiMessageSquare size={40} color="#FFD700" />
              <h6>Video Intercoms</h6>
            </div>
              </NavLink>

              <NavLink className="link" to="/Products/Videocon"  >
              <div className="icon-text-container">
              <FiMonitor size={40} color="#8E44AD" />
              <h6>Video Conferencing</h6>
            </div>
              </NavLink>
            


              <NavLink className="link" to="/Products/Transmission"  >
              <div className="icon-text-container">
              <FiSend size={40} color="green" />
              <h6>Transmission</h6>
            </div>
              </NavLink>

             

              <NavLink className="link" to="/Products/Firealarm"  >
              <div className="icon-text-container">
              <FiAlertCircle size={40} color="#CC0000" />
              <h6>Fire Alarm</h6>
            </div>
              </NavLink>

              <NavLink className="link" to="/Products/Accesscontrol"  >
              <div className="icon-text-container">
              <FiUnlock size={45} color="#641E16" />
              <h6>Access Control &<br/> Time Attendance</h6>
            </div>
              </NavLink>

              
        </div>
      </div>
    </div>
  );
};

export default Storage;
