import React from 'react';
import { useState } from 'react';
import { FiShield, FiTool } from 'react-icons/fi';
import { NavLink } from 'react-router-dom';


const Monitoring = () => {
  // State for details
  const [details] = useState({
    title: 'Monitoring the system',
    description:
      'In any security system monitoring is main task and with our expertise and experience, we took our experience in security management including monitoring aspects. and it involves collecting and analysing information to detect suspicious behavior or unauthorised system changes on your network, defining which types of behavior should trigger alerts, and taking action on alerts as needed.',
  });

  return (
    <div className="two-section-container">
      {/* Left Section - Image and Details */}
      <div className="left-section">
        <div className="image-container">
          <img src="/images/mo.jpg" alt="Sample" />
        </div>
        <div className="details-container">
          <div className="title-container">
            <h2>{details.title}</h2>
          </div>
          <div className="description-container">
            <p>{details.description}</p>
          </div>
        </div>
      </div>

      {/* Right Section - Links */}
      <div className="right-section">
        <div className="solink">
          <NavLink className="link" to="/Solutions/Management">
            <div className="icon-text-container">
              <FiShield size={55} color="#000066" />
              <h6>Management of security system</h6>
            </div>
          </NavLink>

          <NavLink className="link" to="/Solutions/Maintainance"  >
              <div className="icon-text-container">
              <FiTool size={55} color="#990000" />
              <h6>Maintainance & Upgrading</h6>
            </div>
              </NavLink>
        </div>
      </div>
    </div>
  );
};

export default Monitoring;
