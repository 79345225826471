import { React, useEffect, useState } from "react";
//import { Link } from "react-router-dom";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import ImageSlider from "./Clients";
import { FaArrowUp } from 'react-icons/fa'; 
import CardList from "./Cardlist";
import Management from "./Solution/Management";
import Testimonial from './testimonial'; 

const ScrollToTop = ({ isVisible, setIsVisible }) => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      setIsVisible(scrollY > 150);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [setIsVisible]);

  return (
    <div>
      {isVisible && (
        <div className="scroll-to-top" onClick={scrollToTop}>
          <FaArrowUp />
        </div>
      )}
    </div>
  );
};



const Home = () => {

  const [isVisible, setIsVisible] = useState(false);
 
  const products = [
    {
      imageSrc: "./images/products/NetworkCamera.png",
      title: "Network cameras",
      description: "Network cameras, also known as IP cameras, revolutionize surveillance by seamlessly integrating with ...",
      link: "/Products/Networkcameras",
      price: "2000",
     
    },
    {
      imageSrc: "./images/products/PTZcamera.png",
      title: "PTZ cameras",
      description: "PTZ (Pan-Tilt-Zoom) cameras are versatile surveillance devices renowned for their dynamic... ",
      link: "/Products/PTZcameras",
      price: "3000",
      
    },
    {
      imageSrc: "./images/products/NetworkRecord.png",
      title: "Network Records",
      description: "Network Records serve as the vital backbone of digital communication, enabling seamless connectivity and ...",
      link: "/Products/NetworkRecords",
      price: "1000",
      
    },
    {
      imageSrc: "./images/products/VideoIntercom.png",
      title: "Video Intercoms",
      description: "Video intercoms revolutionize communication and security by seamlessly integrating audio and...",
      link: "/Products/Videointercoms",
      price: "2500",
    },
    {
      imageSrc: "./images/products/Transmission.png",
      title: "Transmission",
      description: "Transmission in a security system refers to the communication of data between various components within...",
      link: "/Products/Transmission",
      price: "1500",
    },
    {
      imageSrc: "./images/products/ACTA.png",
      title: "Access control & time attendance",
      description: "Access control and time attendance systems are integral components of...",
      link: "/Products/Accesscontrol",
      price: "1200",
    },
    {
      imageSrc: "./images/products/Videoconferencing.png",
      title: "Video Conferencing",
      description: "Video conferencing revolutionizes communication by seamlessly connecting individuals or groups...",
      link: "/Products/Videocon",
      price: "2500",
    },
    {
      imageSrc: "./images/products/Storage.png",
      title: "Storage",
      description: "Storage is a crucial component in security systems as it involves the retention and management of data...",
      link: "/Products/storage",
      price: "1500",
    },
    {
      imageSrc: "./images/products/FireAlarm.png",
      title: "Fire Alarm",
      description: "A fire alarm is a critical safety device designed to detect the presence of fire and alert occupants to...",
      link: "/Products/FireAlarm",
      price: "1200",
    },
  ];

  return (
    <div className="home">
    <ScrollToTop isVisible={isVisible} setIsVisible={setIsVisible} />

      {/* Slider to display Images with Text */}
      <Carousel autoPlay infiniteLoop interval={3000} showArrows={false} showStatus={false} showThumbs={false}>
        <div className="slide">
          <img src="./images/cam.png" alt="Slide 1" />
          <div className="text">Digital Time camera installation and development services</div>
        </div>
        <div className="slide">
          <img src="./images/aa.jpg" alt="Slide 2" />
          <div className="text">Security Camera installation and development service </div>
        </div>
        <div className="slide">
          <img src="./images/2.jpg" alt="Slide 3" />
          <div className="text">Closed-Circuit Television or CCTV Camera installation and programming</div>
        </div>
        
      </Carousel>


      {/* Card section to display services or products */}
      
      <div className="card-title">
        <h1>Product & Services</h1>
      </div>


      <div className="card-section">
        <CardList products={products} />
      </div>
      <div className="solution"><h1>Our Solutions</h1>
      <Management/>
      </div>
      {/*slider for client */}
      <div className="clients">
        {/* <h1>Our Clients</h1> */}
      <ImageSlider/>
      </div>

      <div className="testimonial">
      <h1>Testimonials</h1>
      <Testimonial />
    </div>

    </div>
  );
};

export default Home;
