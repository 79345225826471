// CardList.jsx
import React, { useState, useEffect } from "react";
import Card from "./Card";

const CardList = ({ products }) => {
  const [visibleCount, setVisibleCount] = useState(3);

  useEffect(() => {
    console.log("Products in CardList:", products);
  }, [products]);

  if (!products || products.length === 0) {
    return <div>No products available</div>;
  }

  const loadMore = () => {
    setVisibleCount((prevCount) => prevCount + 6);
  };

  const visibleProducts = products.slice(0, visibleCount);

  return (
    <div className="card-list">
      {visibleProducts.map((product, index) => (
        <Card key={index} {...product} />
      ))}
      {visibleCount < products.length && (
        <div className="load">
        <button onClick={loadMore}>Load More</button>
        </div>
      )}
    </div>
  );
};

export default CardList;
