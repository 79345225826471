import React from 'react';
import { useState } from 'react';
import { FiTool, FiMonitor } from 'react-icons/fi';
import { NavLink } from 'react-router-dom';


const Management = () => {
  // State for details
  const [details] = useState({
    title: 'Management of security system',
    description:
      'Digital Time security team has the best expertise in the field of security installation and management. Our Company is dedicated to deliver the best services for your company. Since the management needs extra care to give a successful administration of the security system that is already installed in your company, our company gives full consideration on the process management of the security system.Our programs will function compatibly with all of offices located in the capital Addis Ababa and outside of Addis Ababa. The security task is executed with most care and expertise to avoid any risk. Our Company takes the best risk management techniques in the field of security.',
  });

  return (
    <div className="two-section-container">
      {/* Left Section - Image and Details */}
      <div className="left-section">
        <div className="image-container">
          <img src="/images/h4.png" alt="Sample" />
        </div>
        <div className="details-container">
          <div className="title-container">
            <h2>{details.title}</h2>
          </div>
          <div className="description-container">
            <p>{details.description}</p>
          </div>
        </div>
      </div>

      {/* Right Section - Links */}
      <div className="right-section">
        <div className="solink">
        <NavLink className="link" to="/Solutions/Maintainance"  >
              <div className="icon-text-container">
              <FiTool size={55} color="#990000" />
              <h6>Maintainance & Upgrading</h6>
            </div>
              </NavLink>

          <NavLink className="link" to="/Solutions/Monitoring">
            <div className="icon-text-container">
              <FiMonitor size={55} color="#FFCC00" />
              <h6>Monitoring the system</h6>
            </div>
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default Management;
