import React from 'react';

const Testimonial = ({ text, imgsrc, name, role }) => {
  return (
    <figure className="testimonial-figure">
      <blockquote className="testimonial-quote">
      <svg
  class="custom-svg-size mx-auto mb-3 text-gray-400 dark:text-gray-600"
  aria-hidden="true"
  xmlns="http://www.w3.org/2000/svg"
  fill="currentColor"
  viewBox="0 0 18 14"
>
  <path d="M6 0H2a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4v1a3 3 0 0 1-3 3H2a1 1 0 0 0 0 2h1a5.006 5.006 0 0 0 5-5V2a2 2 0 0 0-2-2Zm10 0h-4a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4v1a3 3 0 0 1-3 3h-1a1 1 0 0 0 0 2h1a5.006 5.006 0 0 0 5-5V2a2 2 0 0 0-2-2Z"/>
</svg>


        <p className="testimonial-text">{text}</p>
      </blockquote>
      <figcaption className="testimonial-author">
        <img className="testimonial-avatar" src={imgsrc} alt={name} />
        <div className="testimonial-info">
          <cite className="testimonial-name">{name}</cite>
          <cite className="testimonial-role">{role}</cite>
        </div>
      </figcaption>
    </figure>
  );
};

const testimonials = [
  {
    text:
      "We are extremely satisfied with the security system provided by DigitalTime. The installation process was seamless, and the team was highly professional. Our property now feels secure and protected, thanks to the cutting-edge technology and reliable support from DigitalTime. We highly recommend their services to anyone looking for top-notch security solutions.",
      imgsrc: '/images/Testi/shoa.png',
    name: "Shoa Super & Hypermarket",
    role: " ",
  },
  {
    text:
      "Choosing DigitalTime for our security system was one of the best decisions we made for our business. The system is user-friendly, and the advanced features provide us with peace of mind. The team at DigitalTime demonstrated exceptional expertise and responsiveness throughout the entire process. We feel confident in the security of our premises, thanks to their state-of-the-art solutions and outstanding customer service.",
    imgsrc: '/images/Testi/wu.png',
    name: "Wolayta University",
    role: "",
  },
  {
    text:
      "From the initial consultation to the final installation, their team exhibited a high level of professionalism and expertise. The system is not only reliable but also customizable to our specific needs. We feel much safer knowing that DigitalTime has our back. Their commitment to security excellence is truly commendable, and we wholeheartedly recommend them to anyone seeking top-tier security solutions.",
    imgsrc: '/images/Testi/mor.png',
    name: "Morning Star Mall",
    role: "",
  },
];

const TestimonialsSection = () => {
  return (
    <div className="testimonial-container">
      {testimonials.map((testimonial, index) => (
        <Testimonial key={index} {...testimonial} />
      ))}
    </div>
  );
};

export default TestimonialsSection;
